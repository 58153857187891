import dashboardIcon from '@/assets/images/dashboard-icon.svg'
import agentVisitIcon from '@/assets/images/agent-visit-icon.svg'
import kmobApkIcon from '@/assets/images/kmob-apk.svg'
import driveFile from '@/assets/images/insert-drive-file.svg'

const menuConfig = [
  {
    is_active: true,
    menu_id: 4,
    menu_name: 'Dashboard',
    image_icon: dashboardIcon,
    fe_route: '/',
    privilege: [
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 11,
        privilege_name: 'Dashboard',
        fe_route: '',
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 12,
        privilege_name: 'Dashboard CRO',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 13,
        privilege_name: 'Dashboard MO/CMO',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        lob_id: 1,
        privilege_id: 55,
        privilege_name: 'Dashboard Customer Management',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 5,
    menu_name: 'Order Form',
    font_icon: 'mdi:format-list-checkbox',
    fe_route: '/submission-form',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 14,
        fe_route: '/submission-form',
        privilege_name: 'List Order Form',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 15,
        privilege_name: 'Form Submission (Tambah Data) Inhouse',
        fe_route: '/submission-form/create',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 16,
        privilege_name: 'Form Submission (Tambah Data) Agent',
        fe_route: '/submission-form/create',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 17,
        privilege_name: 'Form Submission (Tambah Data) Webview',
        fe_route: '/submission-form/create',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 18,
        privilege_name: 'Review \u0026 Assign Order Form KPM',
        fe_route: '/submission-form/review-kpm',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 19,
        privilege_name: 'Review \u0026 Assign Order Form',
        fe_route: '/submission-form/review',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 20,
        privilege_name: 'Detail Order Form',
        fe_route: '/submission-form/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 44,
        privilege_name: 'Cancel Order',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 62,
        privilege_name: 'Delete Order Form',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 63,
        privilege_name: 'View Order Form',
        fe_route: '/kmob/order-form',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 64,
        privilege_name: 'List Order Form',
        fe_route: '/kmob/order-form',
        is_sidebar_menu: true,
        lob_id: 3,

      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 65,
        privilege_name: 'Detail Order Form',
        fe_route: '/kmob/order-form/detail',
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 66,
        privilege_name: 'Cancel',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 67,
        privilege_name: 'Review Order Form',
        fe_route: '/kmob/order-form/review',
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 68,
        privilege_name: 'View PDF PBK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 69,
        privilege_name: 'Submission Order Form',
        fe_route: '/kmob/order-form/create',
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 70,
        privilege_name: 'Retry Filtering',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 1,
    menu_name: 'Role Management',
    font_icon: 'ic:outline-person',
    fe_route: '/role-management',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 1,
        privilege_name: 'List Role',
        fe_route: '/role-management',
        is_sidebar_menu: true,
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 1,
        privilege_name: 'List Role',
        fe_route: '/role-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 1,
        privilege_name: 'List Role',
        fe_route: '/role-management',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 2,
        privilege_name: 'Create Role',
        fe_route: '/role-management/create',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 3,
        privilege_name: 'Update Role',
        fe_route: '/role-management/edit',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 7,
        privilege_name: 'Duplicate Role',
        fe_route: '/role-management/duplicate',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 2,
    menu_name: 'User Management',
    font_icon: 'mdi:account-supervisor-outline',
    fe_route: '/user-management',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 4,
        privilege_name: 'List User',
        fe_route: '/user-management',
        is_sidebar_menu: true,
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 4,
        privilege_name: 'List User',
        fe_route: '/user-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 4,
        privilege_name: 'List User',
        fe_route: '/user-management',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 5,
        privilege_name: 'Create User',
        fe_route: '/user-management/create',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 6,
        privilege_name: 'Update User',
        fe_route: '/user-management/edit',
      },
    ],
  },
  /* disable sementara pakai existing karna masih pentest */
  /*
  {
    is_active: true,
    menu_id: 6,
    menu_name: 'APK / Manajemen Pengajuan',
    font_icon: 'mdi:text-box-edit-outline',
    fe_route: '/application-management',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 21,
        privilege_name: 'List APK',
        fe_route: '/application-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 22,
        privilege_name: 'Form Submission APK',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 23,
        privilege_name: 'Detail APK',
        fe_route: '/application-management/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 45,
        privilege_name: 'Cancel Order',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 73,
        privilege_name: 'Cancel APK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 71,
        privilege_name: 'List APK',
        fe_route: '/kmob/apk',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 72,
        privilege_name: 'Detail APK',
        fe_route: '/kmob/apk/detail',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 75,
        privilege_name: 'Assign Surveyor',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 74,
        privilege_name: 'Review & Edit APK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 111,
        privilege_name: 'Print APK',
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 76,
        privilege_name: 'Filtering APK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 114,
        privilege_name: 'Print PV',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 7,
    menu_name: 'AKK / Manajemen Survey',
    font_icon: 'mdi:briefcase-outline',
    fe_route: '/survey-management',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 24,
        privilege_name: 'List AKK',
        fe_route: '/survey-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 25,
        privilege_name: 'Form Submission APK',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 26,
        privilege_name: 'Detail APK',
        fe_route: '/survey-management/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 27,
        privilege_name: 'Edit APK',
        fe_route: '/survey-management/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 46,
        privilege_name: 'Cancel Order',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 91,
        privilege_name: 'View History Order',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 92,
        privilege_name: 'Submit to LOS',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 93,
        privilege_name: 'Print AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 80,
        privilege_name: 'List AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 85,
        privilege_name: 'Detail AKK',
        fe_route: '/kmob/akk/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 86,
        privilege_name: 'Cancel AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 89,
        privilege_name: 'Form Submission AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 90,
        privilege_name: 'Edit AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 112,
        privilege_name: 'Print AKK',
      },
    ],
  }, */

  /* start temporary menu */
  {
    is_active: true,
    menu_id: 6,
    menu_name: 'APK / Manajemen Pengajuan',
    font_icon: 'mdi:text-box-edit-outline',
    fe_route: '/application-management',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 21,
        privilege_name: 'List APK',
        fe_route: '/application-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 22,
        privilege_name: 'Form Submission APK',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 23,
        privilege_name: 'Detail APK',
        fe_route: '/application-management/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 45,
        privilege_name: 'Cancel Order',
        fe_route: '',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 7,
    menu_name: 'AKK / Manajemen Survey',
    font_icon: 'mdi:briefcase-outline',
    fe_route: '/survey-management',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 24,
        privilege_name: 'List AKK',
        fe_route: '/survey-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 25,
        privilege_name: 'Form Submission APK',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 26,
        privilege_name: 'Detail APK',
        fe_route: '/survey-management/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 27,
        privilege_name: 'Edit APK',
        fe_route: '/survey-management/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 46,
        privilege_name: 'Cancel Order',
        fe_route: '',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 15,
    menu_name: 'APK',
    image_icon: kmobApkIcon,
    fe_route: '/kmob/apk',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 73,
        privilege_name: 'Cancel APK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 71,
        privilege_name: 'List APK',
        fe_route: '/kmob/apk',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 72,
        privilege_name: 'Detail APK',
        fe_route: '/kmob/apk/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 75,
        privilege_name: 'Assign Surveyor',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 74,
        privilege_name: 'Review & Edit APK',
        fe_route: '/kmob/apk/edit',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 197,
        privilege_name: 'Edit APK',
        fe_route: '/kmob/apk/edit',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 17,
    menu_name: 'AKK',
    font_icon: 'mdi:briefcase-outline',
    fe_route: '/kmob/akk',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 91,
        privilege_name: 'View History Order',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 92,
        privilege_name: 'Submit to LOS',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 93,
        privilege_name: 'Print AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 80,
        privilege_name: 'List AKK',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 85,
        privilege_name: 'Detail AKK',
        fe_route: '/kmob/akk/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 86,
        privilege_name: 'Cancel AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 89,
        privilege_name: 'Form Submission AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 90,
        privilege_name: 'Edit AKK',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 112,
        privilege_name: 'Print AKK',
      },
    ],
  },
  /* end temporary */

  {
    is_active: true,
    menu_id: 39,
    menu_name: 'PV',
    image_icon: driveFile,
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 199,
        privilege_name: 'List PV',
        fe_route: '/kmob/pv',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 200,
        privilege_name: 'Detail PV',
        fe_route: '/kmob/pv/detail',
        lob_id: 3,
      },
    ],
  },
  {
    is_active: true,
    menu_id: 12,
    menu_name: 'Customer management',
    font_icon: 'mdi:account-arrow-left-outline',
    fe_route: '/nwg/customer-management',
    privilege: [
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 51,
        privilege_name: 'Form Follow Up',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 50,
        privilege_name: 'Tab Progres Follow Up',
        fe_route: '',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 49,
        privilege_name: 'Assigning',
        fe_route: '/nwg/customer-management',
        is_sidebar_menu: true,
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 48,
        privilege_name: 'Detail Data Customer',
        fe_route: '/nwg/customer-management/detail',
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 47,
        privilege_name: 'List Data Customer Potensial',
        fe_route: '/nwg/customer-management',
        is_sidebar_menu: true,
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 79,
        privilege_name: 'Detail Customer RO',
        fe_route: '/kmob/customer-management/detail',
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 78,
        privilege_name: 'Assigning Customer RO',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 77,
        privilege_name: 'List Customer RO',
        fe_route: '/kmob/customer-management',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 180,
        privilege_name: 'Dashboard',
        fe_route: '/kmb/customer-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 181,
        privilege_name: 'View List Customer',
        fe_route: '/kmb/customer-management',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 185,
        privilege_name: 'Upload File Customer',
        fe_route: '/kmb/customer-management/create',
        lob_id: 2,
      },
    ],
  },
  {
    is_active: true,
    menu_id: 13,
    menu_name: 'Kunjungan Agen',
    image_icon: agentVisitIcon,
    fe_route: '/agent-visit',
    has_children: true,
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 57,
        privilege_name: 'List Kunjungan Agen',
        fe_route: '/agent-visit',
        is_children: true,
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 58,
        privilege_name: 'Detail Kunjungan Agen',
        fe_route: '/agent-visit/detail',
        is_children: false,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 59,
        privilege_name: 'Agen Activity Report',
        fe_route: '/agent-visit/activity-report',
        is_children: true,
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 107,
        privilege_name: 'List Kunjungan Agent',
        fe_route: '/kmob/agent-visit',
        is_sidebar_menu: true,
        lob_id: 3,
      },
    ],
  },
  {
    is_active: true,
    menu_id: 18,
    menu_name: 'Taksasi',
    font_icon: 'mdi:clipboard-text-outline',
    fe_route: '/kmob/taksasi',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 94,
        privilege_name: 'Form Submission Taksasi',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 87,
        privilege_name: 'Review Edit Pembiayaan',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 81,
        privilege_name: 'List Taksasi',
        fe_route: '/kmob/taksasi',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 82,
        privilege_name: 'Detail Taksasi',
        fe_route: '/kmob/taksasi/detail',
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 83,
        privilege_name: 'Cancel Taksasi',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 84,
        privilege_name: 'Submit to LOS',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 95,
        privilege_name: 'View History Order',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 96,
        privilege_name: 'Print Taksasi',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 113,
        privilege_name: 'Print LPK',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 23,
    menu_name: 'Insentif Penjualan',
    font_icon: 'tabler:chart-pie-4',
    fe_route: '/nwg/sales-incentive',
    has_children: true,
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 115,
        privilege_name: 'Detail Insentif',
        fe_route: '/nwg/sales-incentive/detail',
        is_children: false,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 116,
        privilege_name: 'List Insentif CRO',
        fe_route: '/nwg/sales-incentive',
        is_children: true,
        is_sidebar_menu: true,
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 117,
        privilege_name: 'List Insentif BRO',
        is_children: true,
        is_sidebar_menu: true,
        lob_id: 1,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 118,
        privilege_name: 'Update Potongan Insentif',
        is_children: false,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 119,
        privilege_name: 'Export Data Insentif',
        is_children: false,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 120,
        privilege_name: 'Upload Potongan Insentif',
        is_children: false,
      },
    ],
  },
  {
    is_active: true,
    menu_id: 24,
    menu_name: 'CMO Dedicated KPM',
    font_icon: 'material-symbols:person-outline',
    fe_route: '/dedicated-cmo',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 121,
        privilege_name: 'List CMO Dedicated KPM',
        fe_route: '/dedicated-cmo',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 122,
        privilege_name: 'Update CMO Dedicated KPM',
        fe_route: '/dedicated-cmo/edit',
      },
    ],
  },
  {
    is_active: true,
    menu_id: 28,
    menu_name: 'Registrasi Agent',
    font_icon: 'mdi:account-box-edit-outline',
    privilege: [
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 187,
        privilege_name: 'List Register Agent KMB',
        fe_route: '/kmb/agent-registration',
        is_sidebar_menu: true,
        lob_id: 2,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 186,
        privilege_name: 'Detail Register Agent KMB',
        fe_route: '/kmb/agent-registration/detail',
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 188,
        privilege_name: 'Form Register Agent KMB',
        fe_route: '/kmb/agent-registration/form',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 189,
        privilege_name: 'Approval Register Agent SPV KMB',
        fe_route: '/kmb/agent-registration/approval',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 190,
        privilege_name: 'Approval Register Agent BM KMB',
        fe_route: '/kmb/agent-registration/approval',
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 191,
        privilege_name: 'Reject Register Agent KMB',
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 192,
        privilege_name: 'Unduh Form Register Agent KMB',
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 193,
        privilege_name: 'Unduh PKS Agent KMB',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 125,
        privilege_name: 'Detail Register Agent KMOB',
        fe_route: '/kmob/agent-registration/detail',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 126,
        privilege_name: 'List Register Agent KMOB',
        fe_route: '/kmob/agent-registration',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: false,
        is_mobile: false,
        privilege_id: 127,
        privilege_name: 'Form Register Agent KMOB',
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 129,
        privilege_name: 'Review Register Agent KMOB',
        fe_route: '/kmob/agent-registration/review',
        is_sidebar_menu: true,
        lob_id: 3,
      },
      {
        is_cms: true,
        is_mobile: false,
        privilege_id: 130,
        privilege_name: 'Approve Register Agent KMOB',
        fe_route: '/kmob/agent-registration/approval',
        is_sidebar_menu: true,
        lob_id: 3,
      },
    ],
  },
]

export { menuConfig }
