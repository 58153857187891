export const useLoader = defineStore({
  id: 'loader',
  state: () => ({
    isLoading: false,
  }),
  actions: {
    // Fungsi untuk mengaktifkan status loading
    startLoading() {
      if (this.isLoading === false)
        this.isLoading = true
    },
    // Fungsi untuk menonaktifkan status loading
    stopLoading() {
      this.isLoading = false
    },
  },
})
