import { OrderStatus } from '~/type/kmob/order-status'

export const useKmobTaksasi = defineStore('kmob-taksasi', {
  state: () => ({
    detail: {
      order_asset: {
        prospect_id: '',
        created_at: '',
        full_name: '',
        consumer_type: '',
        order_status_name: '',
        id_number: '',
        mobile_phone: '',
        asset_code: '',
        asset_display: '',
        category_id: '',
        manufacturing_year: '',
        no_police: '',
        chassis_number: '',
        machine_number: '',
        odometer: '',
        bpkb_ownership_status_id: '',
      },
      grade_asset: {
        grade_eksterior: '',
        grade_interior: '',
        grade_mesin: '',
      },
      checklist_sparepart: <any>{},
      total_price: {
        total_point: 0,
        total_price: 0,
      },
      checklist_dokumen: {
        bpkb: 'Ya',
        faktur_kendaraan: 'Ya',
        fotokopi_ktp: 'Ya',
        blanko_kwitansi: 'Ya',
        pemohon_sesuai_nama_bpkb: 'Ya',
        nomor_polisi_sama_dengan_stnk_dan_bpkb: 'Ya',
        pernah_mutasi_antar_daerah: 'Ya',
        kendaraan_pernah_balik_nama: 'Ya',
        kendaraan_pernah_ganti_nomor_polisi: 'Ya',
        nosin_noka_sama_dengan_stnk_bpkb_faktur: 'Ya',
        warna_kendaraan_sama_dengan_stnk_dan_bpkb: '',
        nama_stnk_sesuai_dengan_stnk_dan_bpkb: '',
        stnk_hidup: '',
        notice_pajak_stnk_berlaku_sampai: '',
        keterangan_mesin_menyala: '',
        estimasi_biaya_pajak_stnk: '',
      },
      keterangan_tambahan: {
        keterangan_tambahan_surveyor: '',
        keterangan_tambahan_asset_control: '',
        keterangan_tambahan_bm: '',
        keterangan_tambahan_adh_bom: '',
      },
      document_takasi: {
        back_look: '',
        document_lain: '',
        engine_look: '',
        frame_look: '',
        front_look: '',
        odometer_look: '',
        interior_look: '',
        other_look: '',
        right_look: '',
        video_look: '',
        wheel_look: '',
      },
      financing_apk: {
        admin_fee_amount: 0,
        agent_fee_amount: 0,
        agent_fee_percentage: 0,
        amount_of_interest: 0,
        cashback_amount: 0,
        collateral_insurance_amount: 0,
        down_payment: 0,
        effective_rate: 0,
        life_insurance_amount: 0,
        loan_amount: 0,
        max_loan_amount: 0,
        monthly_installments: 0,
        monthly_interest_rate: 0,
        ntf: 0,
        otr_amount: 0,
        provision_fee_amount: 0,
        provision_fee_percentage: 0,
        total_loan: 0,
        yearly_interest_rate: 0,
      },
      financing_taksasi: {
        admin_fee_amount: 0,
        agent_fee_amount: 0,
        agent_fee_percentage: 0,
        amount_of_interest: 0,
        cashback_amount: 0,
        collateral_insurance_amount: 0,
        down_payment: 0,
        effective_rate: 0,
        life_insurance_amount: 0,
        loan_amount: 0,
        max_loan_amount: 0,
        monthly_installments: 0,
        monthly_interest_rate: 0,
        ntf: 0,
        otr_amount: 0,
        provision_fee_amount: 0,
        provision_fee_percentage: 0,
        total_loan: 0,
        yearly_interest_rate: 0,
      },
      document_lpk: {
        print_lpk_url: '',
      },
    },
    reviewData: {
      customer: {
        full_name: '',
      },
      histories: [] as any[],
      product_offering_name: '',
      program_marketing_name: '',
      prospect_id: '',
      created_at: '',
    },
    showReviewDataModal: false,
    isFetchError: false,
    confirmationLink: {
      token_expired: '',
      mobile_phone: '',
      attempt: 0,
      order_status_name: '',
    },
  }),
  actions: {
    changeStatus() {
      this.detail = {
        ...this.detail,
        order_asset: {
          ...this.detail.order_asset,
          order_status_name: 'SALLY-CANCEL',
        },
      }
    },
    async getDetailByProspectId(prospect_id: string) {
      try {
        const { data, error }: any = await useSallyFetchCms(`/v2/kmob/taksasi/${prospect_id}/detail`)

        if (data.value) {
          this.detail = {
            ...this.detail,
            ...data.value.data,
          }
          return data
        }

        if (error.value) {
          this.isFetchError = true
          return error
        }
      }
      catch (error) {
        this.isFetchError = true
        return error
      }
    },
    async submitToLos(prospect_id: string) {
      try {
        const { data, error }: any = await useSallyFetchCms(`/v1/kmob/${prospect_id}/submit/los`, {
          method: 'POST',
        })

        if (data.value)
          return true

        if (error.value)
          return false
      }
      catch (error) {
        return false
      }
    },
    async getReviewDataByProspectId(prospect_id: string) {
      try {
        const { data, error }: any = await useSallyFetchCms(`/v1/kmob/edp/review/${prospect_id}`)

        if (data.value) {
          this.reviewData = {
            ...this.reviewData,
            ...data.value.data,
          }
          return data
        }

        if (error.value) {
          this.isFetchError = true
          return error
        }
      }
      catch (error) {
        this.isFetchError = true
        return error
      }
    },
    async sendConfirmationLink() {
      try {
        const { data, error }: any = await useSallyFetchCms('/v1/kmob/edp/confirmation', {
          method: 'POST',
          body: {
            prospect_id: this.detail?.order_asset?.prospect_id,
            mobile_phone: this.detail?.order_asset?.mobile_phone,
            type: 'SMS',
          },
        })

        if (data.value) {
          this.confirmationLink = {
            ...this.confirmationLink,
            ...data.value.data,
          }
          return true
        }

        if (error.value)
          return false
      }
      catch (error) {
        return false
      }
    },
    async getConfirmationStatus() {
      try {
        const { data, error }: any = await useSallyFetchCms(`v1/kmob/edp/confirmation/${this.detail?.order_asset?.prospect_id}`)

        if (data.value) {
          this.confirmationLink = {
            ...this.confirmationLink,
            ...data.value.data,
          }
          return true
        }

        if (error.value)
          return false
      }
      catch (error) {
        return false
      }
    },
  },
  getters: {
    showReviewDataButton: state => (hasReviewAccess: boolean) => {
      const isLosReturnStatus = state.detail?.order_asset?.order_status_name.toUpperCase() === OrderStatus.LOS_RETURN
      return hasReviewAccess && isLosReturnStatus
    },
  },
})
