export enum OrderStatus {
  NEW = 'NEW',
  SURVEY_NEW = 'SURVEY-NEW',
  SALLY_NEW = 'SALLY-NEW',
  TAKSASI_NEW = 'TAKSASI-NEW',
  SURVEY_UNASSIGNED = 'SURVEY-UNASSIGNED',
  DUPCHECK_PROCESS = 'DUPCHECK-PROCESS',
  SALLY_CONFIRMATION = 'SALLY-CONFIRMATION',
  SALLY_CONFIRMA_CMS = 'SALLY-CONFIRM-CMS',
  SALLY_ASSIGN_EDIT = 'SALLY-ASSIGN-EDIT',
  TAKSASI_APPROVAL_BM = 'TAKSASI-APPROVAL-BM',
  FILTERING_PROCESS = 'FILTERING-PROCESS',
  SURVEY_PROCESS = 'SURVEY-PROCESS',
  TAKSASI_APPROVAL_ADH_BOM = 'TAKSASI-APPROVAL-ADH/BOM',
  SALLY_PROCESS = 'SALLY-PROCESS',
  LOS_PROCESS = 'LOS-PROCESS',
  LOS_RETURN = 'LOS-RETURN',
  SALLY_FINISHED = 'SALLY-FINISHED',
  SURVEY_FINISHED = 'SURVEY-FINISHED',
  LOS_APPROVED = 'LOS-APPROVED',
  TAKSASI_APPROVED = 'TAKSASI-APPROVED',
  FILTERING_FAILED = 'FILTERING-FAILED',
  SALLY_CANCEL = 'SALLY-CANCEL',
  DUPCHECK_FAILED = 'DUPCHECK-FAILED',
  DUPCHECK_2_FAILED = 'DUPCHECK-2-FAILED',
  DUPCHECK_2_PROCESS = 'DUPCHECK-2-PROCESS',
  SALLY_ASSIGN_SURVEY = 'SALLY-ASSIGN-SURVEY',
  TAKSASI_PROCESS = 'TAKSASI-PROCESS',
  TAKSASI_REVIEW = 'TAKSASI-REVIEW',
  DUPCHECK_REJECT = 'DUPCHECK-REJECT',
  DUPCHECK_2_REJECT = 'DUPCHECK-2-REJECT',
  LOS_REJECTED = 'LOS-REJECTED',
  LOS_CANCEL = 'LOS-CANCEL',
  TAKSASI_FINISHED = 'TAKSASI-FINISHED',
  GO_LIVE = 'GO-LIVE',
  PV_PROCESS = 'PV-PROCESS',
  PV_FINISHED = 'PV-FINISHED',
  LOS_PROCESS_FAILED = 'LOS-PROCESS-FAILED',
  APPROVAL_STATUS_AGEN = 'APPROVAL AGEN',
  APPROVAL_STATUS_BM = 'APPROVAL BM',
  APPROVAL_STATUS_SPV = 'APPROVAL SPV',
  APPROVED = 'APPROVED',
  SALLY_CONFIRM_PV = 'SALLY-CONFIRM-PV',
  PV_NEW = 'PV-NEW',
  SALLY_CO_NEW = 'SALLY-CO-NEW',
  SALLY_CO_PROCESS = 'SALLY-CO-PROCESS',
  SALLY_CO_FINISHED = 'SALLY-CO-FINISHED',
  PMK_FILTERING_PROCESS = 'PMK-FILTERING-PROCESS',
  FILTERING_REJECT = 'FILTERING-REJECT',
  REHIT_FILTERING_PROCESS = 'REHIT-FILTERING-PROCESS',
  PMK_FILTERING_REJECT = 'PMK-FILTERING-REJECT',
}

export enum TipeSurvey {
  NO_SURVEY = 'NO SURVEY',
  OPEN_SURVEY = 'OPEN SURVEY',
}
