<script setup>
const props = defineProps({
  populateFile: {
    type: Object,
    required: true,
    default: null,
  },
  placeholder: {
    type: String,
    default: 'Document',
  },
  helperText: {
    type: String,
    default: 'Preview Document',
  },
  width: {
    type: String,
    default: 'mx-auto',
  },
  isDownload: {
    type: Boolean,
    default: true,
  },
})
const showModal = ref(false)
function onClickBox() {
  showModal.value = true
}
const widths = computed(() => {
  return props.width
})
function downloadFile() {
  if (props.populateFile && props.populateFile.url) {
    const link = document.createElement('a')
    link.href = props.populateFile.url
    link.download = props.populateFile.name || 'downloaded_file'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
</script>

<template>
  <div :class="widths">
    <KUpload
      accept="image/png, image/jpeg"
      readonly
      intercept-dialog
      :populate-file="populateFile"
      :placeholder="props.placeholder"
      :helper-text="props.helperText"
      @click="onClickBox"
    />
  </div>
  <KModal
    :show-modal="showModal"
    style="width:650px;"
    section-info="Dokumen rahasia, dimohon untuk kebijaksanaannya!"
  >
    <div>
      <div class="flex items-center justify-between gap-2">
        <div>{{ props.populateFile.name }}</div>
        <Icon class="cursor-pointer" name="ic:baseline-close" size="20" @click="() => showModal = false" />
      </div>
      <div class="mt-4 w-full max-h-[70vh] overflow-y-scroll rounded-xl">
        <img v-if="props.populateFile.file_type !== 'pdf'" :src="props.populateFile.url" class="w-full" alt="document">
        <iframe v-if="props.populateFile.file_type === 'pdf'" :src="props.populateFile.url" style="width: 100%; height: 500px;" frameborder="0" />
      </div>

      <slot name="address_info" />

      <div v-if="props.isDownload" class="-mb-12">
        <div class="mt-4">
          <button class="w-[100%] px-4 py-2 text-white bg-blue-500 rounded" @click="downloadFile">
            Download
          </button>
        </div>
      </div>
    </div>
  </KModal>
</template>
