import { storeToRefs } from 'pinia'
import { menuConfig } from '@/utils/menuConfig'
import { useAuth } from '@/stores/auth'

export default defineNuxtRouteMiddleware(async (to) => {
  const lob = ['kmob', 'nwg']
  const guests = [
    'login',
    'reset-password-id-token',
    'expired-link',
    'verification-email-id-token',
    'kmob-link-confirmation',
    'kmob-link-confirmations',
  ]

  // allow guest access
  // if (guests.includes(to.name as string))
  //   return

  const responseCheckToken = await useAuth().checkToken()
  const { loggedIn, user, branch, activeLob } = storeToRefs(useAuth())
  const tokenCookie = useCookie('token')
  const userCookie = useCookie('user')
  const activeLobCookie = useCookie('active-lob')
  const errorPath = ['/error/400', '/error/404', '/error/500', '/error/403', '/error/408']

  if (responseCheckToken === 401) {
    useAuth().logout()
    tokenCookie.value = null
    userCookie.value = null
  }

  if (to.matched.length === 0) {
    abortNavigation()
    return navigateTo('/error/404')
  }

  if (!errorPath.includes(to.fullPath)) {
    if (tokenCookie.value) {
      loggedIn.value = true
      const userData = userCookie.value
      const activeLobId = activeLobCookie.value

      // menu mapping & check access logic
      const activeMenus = responseCheckToken.data?.menu.filter((menu: any) => menu.is_active) || []
      const accessibleRoutes = activeMenus.flatMap((menu: any) => {
        const menuConfigEntry = menuConfig.find(config => config.menu_id === menu.menu_id) // matching menu in config with menu response

        if (!menuConfigEntry)
          return [] // If menu not found in config, skip

        const menuFeRoute = menuConfigEntry.fe_route ? [menuConfigEntry.fe_route] : [] // it will get fe_route from menu level

        const privilegeFeRoutes = menu.privilege // it will get fe_route from privilege level
          .filter((privilege: any) => privilege.is_cms) // only privilege.is_cms = true
          .flatMap((privilege: any) => {
            const configPrivilege = menuConfigEntry.privilege.find(configPriv => configPriv.privilege_id === privilege.privilege_id)
            return configPrivilege && configPrivilege.fe_route ? [configPrivilege.fe_route] : [] // it will get fe_route from privilege level
          })

        return [...menuFeRoute, ...privilegeFeRoutes] // merge fe_route from menu level and privilege level
      })

      const uniqueAccessibleRoutes = [...new Set(accessibleRoutes)] // remove duplicate fe_routes in accessible routes
      const hasLobPrefix = lob.some(lob => to.fullPath.includes(lob)) // check if the path contains any of the lob prefixes
      // it will make sure that the path will be '/route/{specific route}' or '/{lob}/route/{specific route}'
      const path = to.fullPath.split('/').slice(0, hasLobPrefix ? 4 : 3).join('/')
      const hasAccess = uniqueAccessibleRoutes.includes(path) // check if the path is in the accessible routes

      if (
        !hasAccess
        && to.fullPath !== '/'
        && !to.fullPath.includes('/dashboard')
        && !guests.includes(to.name as string)
        && to.name !== 'select-lob'
      ) {
        abortNavigation()
        return navigateTo('/error/403')
      }
      // end of menu mapping & check access logic

      // persist branch data
      let branchData = []
      if (typeof window !== 'undefined' && loggedIn.value)
        branchData = JSON.parse(localStorage.getItem('branch') || '[]')

      if (userData && branchData) {
        user.value = userData
        branch.value = branchData
        activeLob.value = activeLobId as number | null

        // update user line of business
        const updateUserLob = responseCheckToken?.data?.lob
        if (updateUserLob)
          user.value = { ...user.value, line_of_business: updateUserLob }
      }
    }

    if (tokenCookie.value && to.name === 'login')
      return navigateTo('/')

    if (!tokenCookie.value && !guests.includes(to.name as string)) {
      abortNavigation()
      return navigateTo('/login')
    }
  }
})
